import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import { FaWhatsapp } from "react-icons/fa";
import { SlHome } from "react-icons/sl";
import { GrAnnounce } from "react-icons/gr";
import { BiMessageAdd } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { FaBox } from "react-icons/fa";
import { TbMailPlus } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { CiSettings } from "react-icons/ci";
import Layout from '../components/Layout';
import Settings from '../components/settings/settings';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateTemplate from './Templates/CreateTemplate';
import Broadcast from './broadcast/Broadcast';
import ScheduledBroadcast from './broadcast/ScheduledBroadcast';
import Cookies from 'js-cookie';

import { TbInbox } from "react-icons/tb";
import Templates from './Templates/Templates'


const MiniSidebar = () => {
    const [showText, setShowText] = useState(false);
    const [showLayout, setShowLayout] = useState(false);
    const [showSettings, setSettings] = useState(false);
    const [showTemplates, setTemplates] = useState(false);
    const [showBroadcast, setBroadcast] = useState(false);
    const [showScheduledBroadcast, setScheduledBroadcast] = useState(false);
    const [Active, setActive] = useState('')


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Set the active state based on the current URL path on page load
        const path = location.pathname;
        if (path === '/') {
            setActive('layout');
            setShowLayout(false);
        } else if (path === '/broadcast') {
            setActive('broadcast');
            setBroadcast(false);
        } else if (path === '/ScheduledBroadcast') {
            setActive('ScheduledBroadcast');
            setScheduledBroadcast(false);
        } else if (path === '/template') {
            setActive('templates');
            setTemplates(false);
        } else if (path === '/settings') {
            setActive('setting');
            setSettings(false);
        }
    }, [location]);


    const handleClickLyout = (item) => {
        if (Active === item) return;
        navigate('/')
        setShowLayout(true);
        setSettings(false)
        setTemplates(false)
        setBroadcast(false);
        setActive(item)


    };

    const handleClickSettings = (item) => {
        if (Active === item) return;
        navigate('/settings')
        setSettings(true)
        setShowLayout(false);
        setTemplates(false)
        setBroadcast(false);
        setActive(item)



    };

    const handleHomeClick = (item) => {
        if (Active === item) return;
        navigate('/home')
        setSettings(false)
        setShowLayout(false);
        setTemplates(false)
        setBroadcast(false);
        setActive(item)


    }

    const handleBroadcastClick = (item) => {
        if (Active === item) return;
        navigate('/broadcast')
        setSettings(false)
        setShowLayout(false);
        setTemplates(false);
        setBroadcast(true);
        setActive(item)

    }
    const handleScheduledBroadcastClick = (item) => {
        if (Active === item) return;
        navigate('/ScheduledBroadcast')
        setSettings(false)
        setShowLayout(false);
        setTemplates(false);
        setBroadcast(false);
        setScheduledBroadcast(true);
        setActive(item)

    }

    const handleTemplatesClicks = (item) => {
        if (Active === item) return;
        navigate('/template')
        setSettings(false)
        setShowLayout(false);
        setTemplates(true);
        setBroadcast(false);
        setActive(item)

    }

    return (
        <>

            {showSettings && <Settings />}
            {showLayout && <Layout />}
            {showTemplates && <CreateTemplate />}
            {showBroadcast && <Broadcast />}
            {showScheduledBroadcast && <ScheduledBroadcast />}
            <Navbar
                style={{
                    width: '50px',
                    marginTop: '56px',
                    // height: '800px',
                    height: '100%',
                    backgroundColor: '#F6F6F6',
                    // backgroundColor: 'red',
                    borderTop: ' 2px solid #E5E4E2',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    transition: 'width 0.4s ease-in-out',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'

                }}
                onMouseEnter={() => {
                    // console.log("Mouse in");
                    document.getElementById('mini-sidebar').style.width = '220px';
                    document.getElementById('mini-sidebar').style.zIndex = '2';
                    const borders = document.getElementsByClassName('border-bottom');
                    for (let border of borders) {
                        border.style.width = '218px';
                    }


                    setShowText(true);
                }}
                onMouseLeave={() => {
                    // console.log("Mouse out");
                    document.getElementById('mini-sidebar').style.width = '50px';
                    document.getElementById('mini-sidebar').style.zIndex = '1';
                    const borders = document.getElementsByClassName('border-bottom');
                    for (let border of borders) {
                        border.style.width = '49px';
                    }
                    setShowText(false);
                }}
                id="mini-sidebar"
            >

                {/* <Container style={{ paddingBottom: '720px', }}> */}
                <Container >

                    <Nav className="flex-column">
                        <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                            <FaBox size={25} />
                        </Nav.Link>
                        <div className="border-bottom" style={{ borderBottom: '1px solid #ccc', width: '100%', marginLeft: '-10px', transition: 'width 0.4s ease-in-out' }}></div>
                        <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleClickLyout('layout') }} >
                            <TbInbox size={25} style={{ color: Active === 'layout' ? '#3F906D' : '' }} />

                            <span
                                style={{
                                    // color: 'black',
                                    color: Active === 'layout' ? '#3F906D' : 'black',
                                    marginLeft: '7px',
                                    width: showText ? '70px' : '0',
                                    overflow: 'hidden', // Hide overflow to prevent jankiness
                                    transition: 'width 0.3s ease-in-out', // Transition width
                                }}
                            >Chats</span>
                        </Nav.Link>
                        <div className="border-bottom" style={{ borderBottom: '1px solid #ccc', width: '100%', marginLeft: '-10px', transition: 'width 0.4s ease-in-out' }}></div>

                        {/* <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleHomeClick('home') }}>
                            <SlHome size={18} style={{ color: Active === 'home' ? '#3F906D' : '' }} />
                            <span
                                style={{
                                    // color: 'black',
                                    color: Active === 'home' ? '#3F906D' : 'black',
                                    marginLeft: '7px',
                                    width: showText ? '70px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                }}
                            >Home</span>
                        </Nav.Link> */}


                        <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleBroadcastClick('broadcast') }}>
                            <GrAnnounce size={18} style={{ color: Active === 'broadcast' ? '#3F906D' : '' }} />
                            <span
                                style={{
                                    marginLeft: '7px',
                                    // color: 'black',
                                    color: Active === 'broadcast' ? '#3F906D' : 'black',
                                    width: showText ? '100px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                }}
                            >Broadcast</span>
                        </Nav.Link>

                        <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleScheduledBroadcastClick('ScheduledBroadcast') }}>
                            <SlCalender size={18} style={{ color: Active === 'ScheduledBroadcast' ? '#3F906D' : '' }} />
                            <span
                                style={{
                                    marginLeft: '7px',
                                    color: 'black',
                                    width: showText ? '170px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                    whiteSpace: 'nowrap',
                                }}
                            >Scheduled Broadcasts</span>
                        </Nav.Link>

                        {Cookies.get('auth_usertype') !== '5' && (<Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleTemplatesClicks('templates') }}>
                            <TbMailPlus size={18} style={{ color: Active === 'templates' ? '#3F906D' : '' }} />
                            <span
                                style={{
                                    marginLeft: '7px',
                                    // color: 'black',
                                    color: Active === 'templates' ? '#3F906D' : 'black',
                                    width: showText ? '70px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                }}
                            >Templates</span>
                        </Nav.Link>)}

                        {/* <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                            <IoIosPeople size={18} />
                            <span
                                style={{
                                    marginLeft: '7px',
                                    color: 'black',
                                    width: showText ? '70px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                }}
                            >Lead</span>
                        </Nav.Link> */}

                        {/* <Nav.Link style={{ color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => { handleClickSettings('setting') }}>
                            <CiSettings size={18} style={{ color: Active === 'setting' ? '#3F906D' : '' }} />
                            <span
                                style={{
                                    marginLeft: '7px',
                                    // color: 'black',
                                    color: Active === 'setting' ? '#3F906D' : 'black',
                                    width: showText ? '70px' : '0',
                                    overflow: 'hidden',
                                    transition: 'width 0.3s ease-in-out',
                                }}
                            >Settings</span>
                        </Nav.Link> */}

                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default MiniSidebar;
