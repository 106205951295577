import React, { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormControl, Form, Button } from 'react-bootstrap';

import { IoSearchOutline } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";

import { FaEllipsisV, FaLongArrowAltUp } from 'react-icons/fa';
import { PiDotsThree } from "react-icons/pi";


import { FiSend } from "react-icons/fi";
// import backgroundImage from '../images/backgroudImage.jpeg'

import { LuMailPlus } from "react-icons/lu";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTemplateFromTable_v1, deleteTemplate_v1, getScheduledBroadcast_v1, deleteScheduledBroadcast_v1 } from '../../Apis/config/Url';
import { GET, POST } from '../../Apis/config/RequestType';
import Caller from '../../Apis/config/Caller';
import { VscLinkExternal } from "react-icons/vsc";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineEmail, MdLocalPhone } from "react-icons/md";
import { RiContactsBookLine } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { ClipLoader, HashLoader, PuffLoader } from 'react-spinners';
import { LuCalendarClock } from "react-icons/lu";



const ScheduledBroadcast = () => {
    const [templateStatus, setTemplateStatus] = useState("ALL");
    const navigate = useNavigate();
    const [templatePage, setTemplatePage] = useState(1);
    const templateDivRef = useRef(null);
    const [totalTemplatePages, setTotalTemplatePages] = useState(null);
    const [templatesDetails, setTemplatesDetails] = useState([])
    const [search, setSearch] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectedTemplateID, setSelectedTemplateID] = useState('')
    const [selectedTemplateName, setSelectedTemplateName] = useState('')
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        const fetchTemplate = async () => {
            try {

                const getTemplate = await Caller(`${getScheduledBroadcast_v1}?page=${Number(templatePage)}`, '', GET, false, false);

                setTemplatesDetails(getTemplate.data.data)
                setTotalTemplatePages(getTemplate.data.totalPages);
            } catch (error) {
                console.error('Error fetching template details:', error);
            }
        };

        fetchTemplate();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (templateDivRef.current) {
                templateDivRef.current.scrollTop = 0;
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const templateStatusList = [
        { value: 'ALL', label: 'All' },
        { value: 'APPROVED', label: 'Approved' },
        { value: 'IN REVIEW', label: 'Pending' },
        { value: 'REJECTED', label: 'Rejected' },
    ];


    const handleSelect = (eventKey) => {
        setTemplateStatus(eventKey);
        handleTemplateSearch({ target: { value: searchQuery } });
    };

    const handleTemplateScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;
        console.log("Scroll bottom:", Math.floor(scrollBottom))
        if (scrollTop === 0) {
            console.log("TOP");
        }
        console.log('totalTemplatePages', totalTemplatePages)
        if (Math.floor(scrollBottom) <= 0 && templatePage < totalTemplatePages) {
            setTemplatePage(Number(templatePage + 1))

            console.log("templatePage = ", Number(templatePage + 1));
            const getTemplate = await Caller(`${getTemplateFromTable_v1}?page=${Number(templatePage + 1)}`, '', GET, false, false);
            console.log('gettemplate on scrolling bottom', getTemplate);

            // setTempTemplateData(prevTemp => [...teptTemplateData, ...getTemplate.data.data]);
            setTemplatesDetails(prevTemp => [...templatesDetails, ...getTemplate.data.data])

        }
    }

    const handleClearSearch = () => {
        setSearch(false)
        setSearchQuery('');
    }

    const handleTemplateSearch = async (e) => {
        console.log('templateStatus', templateStatus)
        setSearchQuery(e.target.value)
        const params = new URLSearchParams();
        try {
            if (e.target.value) {
                params.append('s', e.target.value);
            }
            if (templateStatus !== 'ALL') {
                params.append('status', templateStatus);
            }
            if (e.target.value || templateStatus) {
                // const getTemplate = await Caller(`${getTemplateFromTable_v1}?${params.toString()}`, '', GET, false, false);
                // setTemplateData(getTemplate.data.data);
                // setTemplateCount(getTemplate.data.count)
                // setTempTemplateData(getTemplate.data.data);
                // setTemplatesDetails(getTemplate.data.data)
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
        }
    };

    const openDeleteModal = (broadcastID, templateName) => {
        setSelectedTemplateID(broadcastID);
        setSelectedTemplateName(templateName);
        setShowDeletePopup(true);
    };

    const handleDeleteClick = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("id", selectedTemplateID);
        const delete_template = await Caller(`${deleteScheduledBroadcast_v1}`, formData, 'POST', false, true)

        if (delete_template.success) {
            setLoading(false)
            setTemplatesDetails(prevTemplates => prevTemplates.filter(template => template.broadcast_id !== selectedTemplateID));
            enqueueSnackbar('Scheduled Template Deleted Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            // setShowBroadcastDetails(false)
            setShowDeletePopup(false)
        }
        else {
            console.log('Scheduled Template not deleted')
        }


    }





    return (
        <Container fluid style={{ overflowY: 'hidden', height: 'calc(100vh - 58px)', border: '1px solid #e5e4e2' }}>
            <Row style={{ border: '1px solid #E5E4E2', marginLeft: '37px', overflowY: 'hidden', height: 'calc(100vh - 58px)', }}>
                <Col style={{ padding: 0, border: '1px solid #E5E4E2' }}>

                    <div style={{ height: '60px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <h6>Scheduled Broadcast</h6>
                        </div>
                    </div>

                    <div style={{ flex: 1, width: '100%', overflowY: 'auto', scrollbarWidth: 'thin', msOverflowStyle: 'thin', height: 'calc(100vh - 120px)' }} ref={templateDivRef} onScroll={handleTemplateScroll}>

                        {loading && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
                            zIndex: 9999, // higher than other elements
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HashLoader color="#176433" size={100} />
                        </div>}
                        {templatesDetails.map((template, index) => (
                            <div key={index} style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {/* <div style={{ display: 'flex', marginLeft: '20px' }}>
                                    <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.template_name}</div>
                                    <span style={{ color: '#a19f9f' }}>•</span>
                                    <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.status}</div>
                                    <span style={{ color: '#a19f9f' }}>•</span>
                                    <div style={{ fontWeight: 'bold', color: '#a19f9f' }}>{`Created By ${template.user_details}`}</div>
                                </div> */}
                                <div style={{ padding: '10px', width: '50%', height: 'auto', alignItems: 'center' }}>
                                    <div style={{ padding: '10px', width: '100%', display: 'flex', flexDirection: 'row', border: '1px solid #E5E4E2', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', position: 'relative', }}>
                                        <div style={{ border: '3px solid #E5E4E2', borderRadius: '5px', height: '20%', width: '50%', alignContent: 'flex-start', position: 'relative' }}>
                                            {/* {template.image ? (<img src={template.image} alt="Description of image" style={{ width: '100%', height: 'auto', padding: 0 }} />) : ('')}
                                            {template.description} */}

                                            {template.image_path && <div>
                                                {/* <img src={`${template?.image_url}`} alt="Description of image" style={{ */}
                                                <img src={template.image_path} style={{
                                                    width: '100%', height: '80%', padding: 0, borderTopLeftRadius: '7px',
                                                    borderTopRightRadius: '7px'
                                                }} />
                                            </div>}
                                            <div>
                                                <div style={{ padding: '5px', width: '100%', wordWrap: 'break-word' }}>
                                                    {template.body ? template.body : ""}
                                                </div>

                                                <div style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    display: "flex",
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    {(template.url_btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        display: 'flex',
                                                        paddingBottom: '5px',
                                                        color: '#00BFFF',
                                                        borderTop: '1px solid #ccc',
                                                        alignItems: 'center'
                                                    }}>
                                                        <a href={`https://${template.url_link}`} target='_blank' style={{
                                                            width: '100%',
                                                            textDecoration: 'none',
                                                            padding: 2,
                                                            justifyContent: 'center',
                                                            fontWeight: 'bold',
                                                            display: 'flex',
                                                            paddingBottom: '5px',
                                                            color: '#00BFFF',
                                                            alignItems: 'center'
                                                        }}> <VscLinkExternal style={{ marginRight: '5px' }} />{template.url_btn_name} </a>
                                                    </div>}
                                                    {(template.btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        marginBottom: '10px',
                                                        borderTop: '1px solid #ccc',
                                                        color: '#00BFFF',
                                                        alignItems: 'center'
                                                    }}>
                                                        <MdLocalPhone style={{ marginRight: '5px' }} />{template.btn_name}
                                                    </div>}
                                                    <div style={{ width: '100%', textAlign: 'left', padding: '5px 5px', wordBreak: 'break-word', borderBottom: '1px solid #ccc', borderTop: '1px solid #ccc', fontWeight: 'bold', fontSize: '16px', display: 'flex', }}>
                                                        {template.whatsapp_group_name}
                                                    </div>
                                                    <div style={{ width: '100%', padding: '5px 5px', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                                        <LuCalendarClock style={{ marginRight: '10px' }} />
                                                        <span>{template.date_time}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        {/* <div style={{ position: 'absolute', top: '5px', right: '10px' }}>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                                    <PiDotsThree className="icon" style={{ color: 'black' }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{padding:'1px'}}>
                                                  
                                                    <Dropdown.Item onClick={() => openDeleteModal(template.broadcast_id, template.template_name)}>Delete</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { navigate('/create_template', { state: { templateDetails: template } }) }}>Edit</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}

                                        <div style={{ position: 'absolute', bottom: '5px', right: '10px', fontSize: '12px' }}>
                                            Status : {new Date() < new Date(template.date_time) ? 'Enqueued' : 'Delivered'}
                                        </div>
                                    </div>
                                </div>
                                {index < templatesDetails.length - 1 && <hr style={{ border: '1px solid #E5E4E2', width: '100%', margin: '10px 0' }} />}
                            </div>
                        ))}
                    </div>

                    {/* Delete Popup */}

                    <Modal show={showDeletePopup} onHide={() => { setShowDeletePopup(false) }} centered>
                        <Modal.Body>Are you sure you want to delete this Template?</Modal.Body>
                        <Modal.Footer>
                            <Button style={{ color: "#6c757d", backgroundColor: 'transparent', border: 'none' }} onClick={() => { setShowDeletePopup(false) }}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => handleDeleteClick()}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </Col>

            </Row>
            <SnackbarProvider style={{ marginTop: '15%' }} />
        </Container>
    )
}

export default ScheduledBroadcast