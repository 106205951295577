import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Caller from './Apis/config/Caller';
import { sendToken_v1 } from './Apis/config/Url';
import { POST } from './Apis/config/RequestType';
// import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_APP_ID, VAP_ID_KEY } from './Apis/config/ResponseHandler';
import Cookies from "js-cookie";
import { EMP_USER_ID } from './Apis/config/ResponseHandler';

const firebaseConfig = {
    // apiKey: FIREBASE_API_KEY,
    // authDomain: FIREBASE_AUTH_DOMAIN,
    // // databaseURL: FIREBASE_DATABASE_URL,
    // projectId: FIREBASE_PROJECT_ID,
    // storageBucket: FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    // appId: FIREBASE_APP_ID,

    apiKey: "AIzaSyBtYPHZymyFKO9qIkezNwIncTuz14IYbKg",
    authDomain: "communication-f6dc0.firebaseapp.com",
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: "communication-f6dc0",
    storageBucket: "communication-f6dc0.appspot.com",
    messagingSenderId: "518602208532",
    appId: "1:518602208532:web:b6a0c2c890e551c0a97e01",
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestFirebaseNotificationPermission = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAP_ID_KEY });

        // console.log("currentToken GENERATED ======== ", currentToken);

        if (currentToken) {
            let user_id = Cookies.get(EMP_USER_ID);
            console.log('Notification permission granted. Token:', currentToken);
            const formData = new FormData();
            formData.append('device_token', currentToken);
            formData.append('created_by', user_id);

            await Caller(sendToken_v1, formData, POST, false, true).then((res) => {
                // console.log("res = ",res);
                if (res.success) {
                    // console.log('yeah token saved')
                }
            })

            // return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // return null;
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
        return null;
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
