import React, { useRef, useState, useEffect } from 'react'
import { Button, Form, Popover, Overlay } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import './test1.css';
import { Dropdown, ListGroup, FormControl, InputGroup, DropdownButton } from 'react-bootstrap';
import { FaSearch, FaCheck, FaEllipsisV } from 'react-icons/fa';
import { AiOutlineSearch, AiOutlineCheck } from 'react-icons/ai';
import { IoSearchOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { MdSearch, MdDone } from 'react-icons/md';
import { HiUserAdd } from "react-icons/hi";
import { FaTrash, FaUsers } from 'react-icons/fa';
import { MdGroups } from "react-icons/md";
import { LuSend } from "react-icons/lu";

import { VscWordWrap } from 'react-icons/vsc';
import backgroundImage from '../../images/whatsappImage.jpeg';
import Modal from 'react-bootstrap/Modal';
import TemplatePopup from '../TemplatePopup';
import countryData from './country.json';  // Import the JSON file
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { FaSortUp, FaSortDown } from 'react-icons/fa'; // Add this line
import * as XLSX from 'xlsx';
import { MdCheck } from "react-icons/md";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import { getAllBroadcasts_v1, deleteParticipant_v1, addBroadcasts_v1, createContact_v1, getAllParticipants_v1, deleteBroadcast_v1, createParticipant_v1, importBulkContact_v1 } from '../../Apis/config/Url';
import Caller from '../../Apis/config/Caller';
import { POST, GET, DELETE, PUT } from '../../Apis/config/RequestType';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { ClipLoader, HashLoader, PuffLoader } from 'react-spinners';
import { MdLocalPhone } from "react-icons/md";
import { VscLinkExternal } from "react-icons/vsc";
import { RiCheckDoubleFill } from "react-icons/ri";
import { getAllChatTemplate_v1 } from '../../Apis/config/Url';
import { RiFileExcel2Fill } from "react-icons/ri";
import { useNavigate, useLocation } from 'react-router-dom';
import { requestFirebaseNotificationPermission, onMessageListener } from '../../firebaseConfig';
import socketIOClient from 'socket.io-client';
import { BASE_URL } from '../../Apis/config/Url';
import io from 'socket.io-client';
import { RiCheckFill } from "react-icons/ri";

const ENDPOINT = "http://localhost:3020";
const socket = io(`${BASE_URL}`);

const Broadcast = () => {

    const [items, setItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null); // Add state to store selected item ID
    const [selectedItem, setSelectedItem] = useState();
    const [selectedmembers, setSelectedMembers] = useState();
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = useState(false);
    const [participants, setParticipants] = useState('');
    const [showAddParticipant, setShowAddParticipant] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [countryCode, setCountryCode] = useState('+91');
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(countryData[0]);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isSearchVisibleFirst, setIsSearchVisibleFirst] = useState(false);
    const [searchQueryColumn1, setSearchQueryColumn1] = useState('');
    const [searchQueryColumn3, setSearchQueryColumn3] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermParticipant, setSearchTermParticipant] = useState('');
    const [sortOrder, setSortOrder] = useState('Name (A-Z)'); // State for sorting order
    const [sortColumn, setSortColumn] = useState('whatsapp_group_name')
    // const [sortColumn, setSortColumn] = useState('broadcast_name')
    const [sortType, setSortType] = useState('ASC')
    const [isInitialSortApplied, setIsInitialSortApplied] = useState(false); // New state
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showNewListModal, setShowNewListModal] = useState(false); // State to manage modal visibility
    const target = useRef(null);
    const [data, setData] = React.useState(null);
    const [navbarHeight, setNavbarHeight] = useState(58);
    const [listName, setListName] = useState('');
    const [showContact, setShowContact] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [showBroadcastDetails, setShowBroadcastDetails] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [typeMessageImage, setTypeMessageImage] = useState('');
    const [cliploading, setClipLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const moment = require('moment');
    const [broadCastPage, setBroadcastPage] = useState(1);
    const [participantPage, setParticipantPage] = useState(1);
    const [chatPage, setChatPage] = useState(1);
    const [totalBroadcastPages, setTotalBroadcastPages] = useState(null);
    const [totalParticipantPages, setTotalParticipantPages] = useState(null);
    const [totalChatPages, setTotalChatPages] = useState(null);
    const [chatMessagesTemp, setChatMessagesTemp] = useState([])
    const [isFileMapOpen, setIsFileMapOpen] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedFileImport, setSelectedFileImport] = useState(null);
    const [isTokenFound, setTokenFound] = useState(false);
    const fileInputRef = useRef(null);
    const [isCreating, setIsCreating] = useState(false);
    const isProcessing = useRef(false);

    const navigate = useNavigate();


    const [formDataImport, setFormDataImport] = useState({
        firstName: { selectedColumn: '', data: [] },
        lastName: { selectedColumn: '', data: [] },
        phoneNumber: { selectedColumn: '', data: [] },
        email: { selectedColumn: '', data: [] }
    });

    useEffect(() => {
        const firstNameColumn = columnNames.find(col => col.toLowerCase() === 'first_name');
        const lastNameColumn = columnNames.find(col => col.toLowerCase() === 'last_name');
        const mobileColumn = columnNames.find(col => col.toLowerCase() === 'mobile');
        const emailColumn = columnNames.find(col => col.toLowerCase() === 'email');

        // Ensure columns exist and are correctly mapped
        const mapColumnData = (column) => {
            const columnIndex = columnNames.indexOf(column);
            return columnIndex !== -1 ? data.map(row => row[columnIndex]) : [];
        };

        setFormDataImport(prevState => ({
            firstName: { selectedColumn: firstNameColumn || '', data: mapColumnData(firstNameColumn) },
            lastName: { selectedColumn: lastNameColumn || '', data: mapColumnData(lastNameColumn) },
            phoneNumber: { selectedColumn: mobileColumn || '', data: mapColumnData(mobileColumn) },
            email: { selectedColumn: emailColumn || '', data: mapColumnData(emailColumn) }
        }));

        setIsSubmitDisabled(!(firstNameColumn && mobileColumn));
    }, [columnNames, data]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        const columnIndex = columnNames.indexOf(value);

        if (columnIndex !== -1) {
            const mappedColumnData = data.map(row => row[columnIndex]);

            setFormDataImport(prevState => {
                const updatedState = {
                    ...prevState,
                    [name]: {
                        selectedColumn: value,
                        data: mappedColumnData,
                    },
                };

                const isFirstNameSelected = updatedState.firstName?.selectedColumn;
                const isPhoneNumberSelected = updatedState.phoneNumber?.selectedColumn;

                setIsSubmitDisabled(!(isFirstNameSelected && isPhoneNumberSelected));

                return updatedState;
            })
        }
    };
    const handleSubmit = async () => {
        setIsSubmitDisabled(true)
        const formData = new FormData();

        const firstNames = formDataImport.firstName.data || [];
        const lastNames = formDataImport.lastName.data || [];
        const phoneNumbers = formDataImport.phoneNumber.data || [];
        const email = formDataImport.email.data || []
        const groupId = selectedItemId;

        // Ensure all arrays have the same length
        const maxLength = Math.max(firstNames.length, lastNames.length, phoneNumbers.length);

        // Create the fileData array and filter out empty rows
        const rawData = Array.from({ length: maxLength }).map((_, index) => ({
            First_Name: firstNames[index] || '',
            Last_Name: lastNames[index] || '',
            Mobile: phoneNumbers[index] || '',
            email: email[index] || '',
            Group_id: groupId.toString()
        }));
        console.log('rawData before filtering:', rawData);

        const fileData = rawData.filter(data => data.First_Name && data.Mobile);
        console.log('fileData after filtering:', fileData);

        // console.log('formdata====', formData);
        // console.log('filedata====', fileData);

        formData.append('fileHeader', JSON.stringify(["First_Name", "Last_Name", "Mobile", 'Group_id', 'email']));
        formData.append('fileData', JSON.stringify(fileData));

        const importedContact = await Caller(`${importBulkContact_v1}`, formData, POST, false, true);

        if (importedContact.message === "Data imported successfully.") {
            setIsFileMapOpen(false);
            setIsSubmitDisabled(false)
            enqueueSnackbar('Data imported successfully.', {
                variant: 'success',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.message === "All contacts updated successfully.") {
            setIsFileMapOpen(false);
            setIsSubmitDisabled(false)
            enqueueSnackbar('Contact updated successfully.', {
                variant: 'success',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.message === "Data partially uploaded.") {
            setIsFileMapOpen(false);
            setIsSubmitDisabled(false)
            enqueueSnackbar('Data partially uploaded.', {
                variant: 'warning',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.message === "Unable to upload data. All rows were invalid.") {
            setIsFileMapOpen(false);
            enqueueSnackbar('Unable to upload data. All rows were invalid.', {
                variant: 'error',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.message === "Skipping row due to invalid data types:") {
            setIsFileMapOpen(false);
            enqueueSnackbar('Unable to import data please check the file.', {
                variant: 'error',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.message === "There is no data in the Excel file.") {
            setIsFileMapOpen(false);
            enqueueSnackbar('There is no data in the Excel file.', {
                variant: 'error',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
        else if (importedContact.success === false) {
            setIsSubmitDisabled(false)
            enqueueSnackbar('Unable to import data.', {
                variant: 'error',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });
        }
    };

    useEffect(() => {
        const fetchbroadcast = async () => {
            try {

                const getBroadcasts = await Caller(`${getAllBroadcasts_v1}?page=${broadCastPage}&sortColumn=${sortColumn}&sortType=${sortType}`, '', GET, false, false);
                setTotalBroadcastPages(getBroadcasts.data.totalPages);

                if (getBroadcasts.success) {
                    const fetchedData = getBroadcasts.data.data;

                    setItems(fetchedData.map(broadcast => ({
                        id: broadcast.id,
                        name: broadcast.broadcast_name,
                        members: broadcast.participant_count, // Adjust this as necessary

                    })));

                    if (fetchedData.length > 0) {

                        setSelectedMembers(fetchedData[0].whatsapp_group);
                        setSelectedItemId(fetchedData[0].id);
                    }
                }

            } catch (error) {
                console.error('Error fetching template details:', error);

            }
        };

        fetchbroadcast();
    }, []);


    const handleItemClick = (name, members, id) => {
        const selected = items.find(item => item.name === name);

        setChatMessagesTemp([]);

        setIsSearchVisible(false)
        setChatPage(1)
        setParticipantPage(1)
        setSelectedItem(name);
        setSelectedMembers(members);
        setSelectedItemId(id); // Set the selected item ID
        handleGetChat(id);
        handleGetParticipant(id)
        setShowBroadcastDetails(true)
        setShowContact(false);
    };

    const handleGetParticipant = async (id) => {

        const get_allParticipant = await Caller(`${getAllParticipants_v1}?selectedItemId=${id}&page=${participantPage}&s=${searchTermParticipant}`, '', GET, false, false);
        setParticipants(get_allParticipant.data.data)
        setTotalParticipantPages(get_allParticipant.data.totalPages)


    }

    const handleGetChat = async (id) => {
        setClipLoading(true)

        const get_allChats = await Caller(`${getAllChatTemplate_v1}?id=${id}&page=1`, '', GET, false, false);

        if (get_allChats.success) {
            setChatMessagesTemp(get_allChats.data.data);
            setTotalChatPages(get_allChats.data.totalPages)
        } else {
            setChatMessagesTemp([]);
        }
        setClipLoading(false)

        setTimeout(() => {
            requestAnimationFrame(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
                }
            });
        }, 0);

    }

    useEffect(() => {

        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
        }

    }, [chatMessagesTemp]);




    const handleCountryChange = (country) => {
        setSelectedCountry(country);
    };

    const handleAddParticipantClick = () => {
    };

    const handleDeleteClick = async () => {
        const delete_broadcast = await Caller(`${deleteBroadcast_v1}?selectedItemId=${selectedItemId}`, '', 'DELETE', false, false)

        if (delete_broadcast.success) {
            enqueueSnackbar('Broadcast Deleted Successfully.', { variant: 'success', autoHideDuration: 1000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            setShowBroadcastDetails(false)
            setShowDeletePopup(false)
        }
        else {
            console.log('Broadcast not deleted')
        }

    }

    const columnStyle = {
        // border: '1px solid #E5E4E2',
        border: '0.5px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
    };
    const handleAddParticipantsClick = () => {
        setShowAddParticipant(true)


    }
    const handleSearchClick = () => {
        setIsSearchVisible(!isSearchVisible);
        setSearchTermParticipant('')
        getParticipantSearch();
        setParticipantPage(1)

    }
    const handleSearchClickfirst = () => {

        setIsSearchVisibleFirst(true)
        setBroadcastPage(1)

    }


    const handleCloseModal = () => {
        setShowAddParticipant(false);
        setFirstName('')
        setLastName('');
        setCountryCode('91')
        setName('');
        setPhone('');
    };
    const handleKeyPress = (e) => {
        // Allow only numbers and control keys (like backspace)
        if (
            // Check if the input is a number
            /[0-9]/.test(e.key) &&
            (
                // Allow the first digit to be 6, 7, 8, or 9
                (e.target.value === "" && ["6", "7", "8", "9"].includes(e.key)) ||
                // Allow other digits after the first digit is 6, 7, 8, or 9
                (e.target.value !== "" && /[0-9]/.test(e.key))
            )
        ) {
            // Input is valid
        } else if (
            // Allow control keys (like backspace, delete, arrow keys)
            ["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)
        ) {
            // Control key is allowed
        } else {
            // Prevent default for all other keys
            e.preventDefault();
        }
    };

    const handlePhoneChange = (event) => {
        const { value } = event.target;
        const numericValue = value.replace(/\D/g, '');
        setPhone(numericValue);
        setIsValidPhone(numericValue.length >= 10);
    };

    const handleCountryCodeChange = (code) => {
        setCountryCode(code);
    };

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleSendTemplateClick = () => {
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    // search
    const getAllBroadcasts = () => {
        // return Object.values(items);
        return items
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleSortOrderChange = (order) => {
        setBroadcastPage(1)
        setSortColumn(sortingOptions[order].column)
        setSortType(sortingOptions[order].type)
        setSortOrder(order);
        setDropdownOpen(false);
        getsortedbroadcast();

    };
    useEffect(() => {
        if (isInitialSortApplied) {
            setBroadcastPage(1)
            getsortedbroadcast();
        } else {
            setIsInitialSortApplied(true)
        }

    }, [sortColumn, sortType]);

    const getsortedbroadcast = async () => {
        const response = await Caller(`${getAllBroadcasts_v1}?page=${broadCastPage}&sortColumn=${sortColumn}&sortType=${sortType}`, '', GET, false, false);

        if (response.success) {
            const fetchedData = response.data.data;

            setItems(fetchedData.map(broadcast => ({
                id: broadcast.id,
                name: broadcast.broadcast_name,
                members: broadcast.participant_count,
            })));

        }
    }

    const handleNewListClick = () => {
        setShowNewListModal(true);
    };

    const handleCloseNewListModal = () => {
        setShowNewListModal(false);
        setListName('')
    };
    const handleCloseContact = () => {
        setShowContact(false);

    };
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = '/import_broadcast_participants.xlsx'
        link.download = 'import_broadcast_participants.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            // const sheetData = XLSX.utils.sheet_to_json(sheet);
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const headers = sheetData[0];
            setColumnNames(headers);
            setData(sheetData.slice(1));
        };

        reader.readAsArrayBuffer(file);

    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const fileNameParts = file.name.split('.');
        const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();

        if (extension !== 'xlsx') {
            enqueueSnackbar('Only .XLSX files are allowed!', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            return;
        }
        setSelectedFileImport(file.name);
        // Save the file to the public folder
        handleFileUpload(file);
        e.target.value = null;
    };

    const handleDropdownItemClick = () => {
        // Trigger click event of file input
        fileInputRef.current.click();
    };

    const handleNewListInputChange = (e) => {
        const inputValue = e.target.value;
        const re = /^[a-zA-Z0-9 ]+$/;

        const isAllSpaces = inputValue.trim().length === 0 && inputValue.length > 0;

        if ((re.test(inputValue) || inputValue === "") && !isAllSpaces && inputValue.length <= 100) {
            setListName(inputValue);
        }
    };

    const handleCreateNewList = async () => {
        if (isCreating) return;
        if (listName) {
            setIsCreating(true);
            const formData = new FormData();
            // formData.append('broadcast_name', listName);
            formData.append('whatsapp_group_name', listName.trim());
            const addBroadcast = await Caller(addBroadcasts_v1, formData, POST, false, true)
            if (addBroadcast.success === true) {
                handleCloseNewListModal();
                setIsCreating(false);
                enqueueSnackbar('Broadcast Created successfully.', { variant: 'success', autoHideDuration: 1000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            } else {
                setIsCreating(false);
                enqueueSnackbar('Broadcast Name Already Exist.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            }
        }
    };

    const handleShowContact = (contact) => {
        setSelectedContact(contact);
        setShowContact(true)
    }

    const handleContactRemove = async (participant_id) => {

        const delete_Participant = await Caller(`${deleteParticipant_v1}?participant_id=${participant_id}&broadcast_id_to_remove=${selectedItemId}`, '', 'DELETE', false, false)

        if (delete_Participant.success) {
            enqueueSnackbar('Participant Deleted Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            setShowContact(false);
            setSelectedContact(null);
        } else {
            enqueueSnackbar('Participant Not Deleted.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
        }
    }

    const handleContactMessage = (contact) => {
        navigate('/', { state: { selectedContact: contact } })
        setShowContact(false)
        setSelectedContact(null)
    }


    const addParticipant = async () => {

        const formData = new FormData();
        formData.append('first_name', firstName.trim());
        formData.append('last_name', lastName.trim())
        formData.append('mobile', phone);
        formData.append('group_id', selectedItemId)

        // Placeholder for API call
        const response = await Caller(createParticipant_v1, formData, 'POST', false, true);

        if (response.success) {
            enqueueSnackbar('Participant Added Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            setShowAddParticipant(false);
            setFirstName('');
            setLastName('');
            // setName('')
            setPhone('')
            setCountryCode('91')

        }
        else {
            enqueueSnackbar(response.message, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });

        }


    };

    const handleTempRefresh = (newTemplate) => {
        setChatMessagesTemp((prevTemplateData) => [...prevTemplateData, newTemplate]);


    }

    const handleBroadcastScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (scrollTop === 0) {

        }

        if (Math.floor(scrollBottom) <= 0 && broadCastPage < totalBroadcastPages) {
            setBroadcastPage(Number(broadCastPage + 1))

            try {
                let response = await Caller(`${getAllBroadcasts_v1}?page=${Number(broadCastPage) + 1}&s=${searchTerm}&sortColumn=${sortColumn}&sortType=${sortType}`, '', GET, false, false);
                const newItems = response.data.data.map(broadcast => ({
                    id: broadcast.id,
                    name: broadcast.broadcast_name,
                    members: broadcast.participant_count, // Adjust this as necessary
                }));
                setItems(prevItems => [...prevItems, ...newItems]);
            } catch (error) {
                console.error("Error fetching broadcasts:", error);
            }



        }

    };

    const handleParticipantScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (scrollTop === 0) {

        }

        if (Math.floor(scrollBottom) <= 0 && participantPage < totalParticipantPages) {
            setParticipantPage(Number(participantPage + 1))

            const data = await Caller(`${getAllParticipants_v1}?selectedItemId=${selectedItemId}&page=${Number(participantPage) + 1}&s=${searchTermParticipant}`, '', GET, false, false);


            setParticipants(prevContacts => [...participants, ...data.data.data]);



        }

    };

    const handleChatScroll = async (e) => {

        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (scrollTop === 0) {

        }

        if (Math.floor(scrollTop) == 0 && chatPage < totalChatPages) {

            const oldScrollHeight = e.target.scrollHeight;

            setChatPage(prevChatPage => prevChatPage + 1);

            const data = await Caller(`${getAllChatTemplate_v1}?id=${selectedItemId}&page=${Number(chatPage + 1)}`, '', GET, false, false);


            setTotalChatPages(data.data.totalPages);
            setChatMessagesTemp(prevMessages => [...data.data.data, ...prevMessages]);

            setTimeout(() => {
                const newScrollHeight = e.target.scrollHeight;
                const addedHeight = newScrollHeight - oldScrollHeight;
                e.target.scrollTop = addedHeight;
            }, 0); // Timeout to ensure DOM updates have been applied

        }


    }

    const getBroadcastSearch = async (e) => {
        setSearchTerm(e.target.value)

        const response = await Caller(`${getAllBroadcasts_v1}?&s=${e.target.value}&sortColumn=${sortColumn}&sortType=${sortType}`, '', GET, false, false);
        const searchbroadcast = response.data.data
        // if (searchTerm != ''){
        setItems(searchbroadcast.map(broadcast => ({
            id: broadcast.id,
            name: broadcast.broadcast_name,
            members: broadcast.participant_count, // Adjust this as necessary
            // participants: [] // You need to fill this based on your logic or additional API calls
        })));

        // }
    };
    const getParticipantSearch = async (searchTermParticipant = '') => {

        if (searchTermParticipant != null || searchTermParticipant != '') {
            const response = await Caller(`${getAllParticipants_v1}?selectedItemId=${selectedItemId}&s=${searchTermParticipant}`, '', GET, false, false);

            setParticipants(response.data.data)
        }
    };
    const handleSearchClickfirstclose = async (e) => {

        setIsSearchVisibleFirst(false);
        setSearchTerm('');
        setBroadcastPage(1);

        const response = await Caller(`${getAllBroadcasts_v1}?sortColumn=${sortColumn}&sortType=${sortType}`, '', GET, false, false);
        const searchbroadcast = response.data.data
        setItems(searchbroadcast.map(broadcast => ({
            id: broadcast.id,
            name: broadcast.broadcast_name,
            members: broadcast.participant_count, // Adjust this as necessary
            // participants: [] // You need to fill this based on your logic or additional API calls
        })));

    }

    const sortingOptions = {
        'Name (A-Z)': { column: 'broadcast_name', type: 'ASC' },
        'Name (Z-A)': { column: 'broadcast_name', type: 'DESC' },
        // 'Date created - ascending': { column: 'date_time', type: 'ASC' },
        'Date created - ascending': { column: 'created_at', type: 'ASC' },
        // 'Date created - descending': { column: 'date_time', type: 'DESC' },
        'Date created - descending': { column: 'created_at', type: 'DESC' },
    };

    const toggleImportPopup = () => {
        setIsImportOpen(!isImportOpen);
        setSelectedFileImport(null)
    };
    const handleNextClick = () => {
        if (selectedFileImport) {
            setIsImportOpen(false);
            setIsFileMapOpen(true);
        }
    };

    const handleDismissClick = () => {
        setSelectedFileImport(null);
        setIsImportOpen(true);
        setIsFileMapOpen(false);
    };


    //-------------------------FIREBASE START------------------------------------------//

    const captureData = async (data) => {
        let currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
        console.log('data?.data?.new_message', data?.data?.new_message)
        if (data?.data?.data_type === 'broadcast_message') {
            let add_messages = JSON.parse(data?.data?.new_message);


            setChatMessagesTemp(prevParticipants => [...prevParticipants, add_messages]);
            // setting scroll to bottom
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
            }
        }

        if (data?.data?.data_type === 'group_delete') {
            const group_id = Number(JSON.parse(data?.data?.new_message)?.group_id);
            setItems(items.filter(item => item.id !== group_id));
        }

        if (data?.data?.data_type === 'participant_creation') {
            let add_participant = JSON.parse(data?.data?.new_message);
            const { id, first_name, last_name, mobile, group_id } = add_participant
            setParticipants(prevParticipants => [add_participant, ...prevParticipants]);
            setItems(prevItems => prevItems.map(item =>
                item.id === Number(group_id) ? { ...item, members: (parseInt(item.members) + 1).toString() } : item
            ));
            setSelectedMembers(prevCount => prevCount + 1);
        }

        if (data?.data?.data_type === 'import_contact') {
            let import_participants = JSON.parse(data?.data?.new_message);

            const group_id = import_participants[0]?.group_id;
            const participantsCount = import_participants.length;
            setParticipants(prevParticipants => [...import_participants, ...prevParticipants]);

            setItems(prevItems => prevItems.map(item =>
                item.id === Number(group_id) ?
                    { ...item, members: (parseInt(item.members) + participantsCount).toString() } :
                    item
            ));
            setSelectedMembers(prevCount => prevCount + participantsCount);
        }

        if (data?.data?.data_type === 'participant_delete') {
            let deleted_participant = JSON.parse(data?.data?.new_message);
            const { group_id, participants } = deleted_participant;

            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === Number(group_id)
                        ? { ...item, members: (parseInt(item.members) - 1).toString() }
                        : item
                )
            );
            setParticipants(prevParticipants =>
                prevParticipants.filter(participant => participant.id !== Number(participants))
            );
            setSelectedMembers(prevCount => prevCount - 1);
        }

        if (data?.data?.data_type === 'group_creation') {
            let newList = JSON.parse(data?.data?.new_message);
            const newItems = {
                id: newList.id, // Ensure `id` is available in `newList`
                name: newList.broadcast_name,
                members: newList.participant_count || 0, // Use 0 or another default value if `participant_count` isn't available
            };

            setItems(prevItems => [newItems, ...prevItems]);
        }
    }


    // useEffect(() => {
    //     requestFirebaseNotificationPermission()
    //         .then(token => {
    //             if (token) {
    //                 setTokenFound(true);
    //                 // Send token to server to save it for sending notifications
    //                 fetch(`${BASE_URL}subscribe`, {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify({ token }),
    //                 }).then(response => response.json())
    //                     .then(data => {
    //                         console.log('Token saved:', data);
    //                     }).catch(error => {
    //                         console.error('Error saving token:', error);
    //                     });
    //             } else {
    //                 setTokenFound(false);
    //             }
    //         })
    //         .catch(err => {
    //             console.log('An error occurred while requesting permission: ', err);
    //         });
    // }, []);

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        socket.on('notification', (message) => {
            console.log('Notification received:', message);
            // Display notification to the user
        });

        return () => socket.disconnect();
    }, []);

    onMessageListener().then(payload => {
        captureData(payload);

        // Display notification to the user
    }).catch(err => console.log('failed: ', err));




    //-------------------------FIREBASE END--------------------------------------------//

    const sendTemplateBtnDisable = () => {
        if (participants.length > 0) {
            return false;
        } else {
            return true;
        }

    }

    const showChatDateTime = (updated_at) => {
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        // Parse both dates
        const givenMoment = moment(updated_at, 'YYYY-MM-DD HH:mm:ss');
        const currentMoment = moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss');
        const isSameDate = givenMoment.isSame(currentMoment, 'day');
        // return !last_message ? '' : isSameDate ? moment(updated_at).format('hh:mm a') : moment(updated_at).format('DD-MM-YYYY');
        return isSameDate ? moment(updated_at).format('hh:mm a') : moment(updated_at).format('DD-MM-YYYY hh:mm a');
    }


    return (
        <Container fluid style={{ overflowY: 'hidden', height: 'calc(100vh - 58px)', border: '1px solid #e5e4e2' }}>
            <Row className="d-flex" style={{ marginLeft: '37px', height: 'calc(100vh -58px)' }}>
                <Col xs={3} className="column" style={{ ...columnStyle, borderRight: '1px solid #e5e4e2', position: 'relative', height: 'calc(100vh - 58px)' }}><div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '90vh' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #E5E4E2', alignItems: 'center', flexDirection: 'row' }}>
                            {!isSearchVisibleFirst && <div style={{ fontSize: '1rem', width: '100%', padding: '6px', fontWeight: '500' }}>Broadcast Lists</div>}
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                                {isSearchVisibleFirst ? (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingRight: '5px' }}>
                                        <input
                                            type="text"
                                            placeholder="Search segments"
                                            style={{ width: '100%', marginRight: '10px', border: 'transparent', padding: '5px', outline: 'none' }}
                                            value={searchTerm}
                                            // onChange={(e) => setSearchTerm(e.target.value)}
                                            onChange={
                                                // setSearchTerm(e.target.value);
                                                getBroadcastSearch

                                            }

                                        />
                                        <div onClick={handleSearchClickfirstclose} style={{ cursor: 'pointer', fontSize: '1.5rem', marginLeft: '5px' }}>
                                            &times;
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <MdSearch onClick={handleSearchClickfirst} style={{ cursor: 'pointer', marginRight: '12px', fontSize: '1.5rem' }} />
                                        {/* <MdCheck style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.5rem' }} /> */}
                                        <Dropdown align="end">
                                            <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                                <HiOutlineEllipsisVertical style={{ color: 'black', cursor: 'pointer', fontSize: '1.5rem' }} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item type='file' onClick={handleDropdownItemClick}>Import Contacts from Excel</Dropdown.Item> */}
                                                <Dropdown.Item disabled={!selectedItem} onClick={() => { toggleImportPopup() }}>Import Contacts from Excel</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={downloadFile}>Download sample excel format</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <input type="file" ref={fileInputRef} onChange={handleFileInputChange} style={{ display: 'none' }} accept=".xlsx" />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #E5E4E2' }}>
                            <HiMiniArrowsUpDown style={{ marginLeft: '10px' }} />
                            <div style={{ position: 'relative', width: '100%' }}>
                                <div
                                    onClick={toggleDropdown}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        position: 'relative'
                                    }}
                                >
                                    <div style={{ marginRight: '10px', alignContent: 'center' }}>Sort by</div>
                                    <div style={{ fontWeight: '650' }}>{sortOrder}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '10px' }}>
                                        {dropdownOpen ? <FaSortUp /> : <FaSortDown />}
                                    </div>
                                </div>
                                {dropdownOpen && (
                                    <div style={{
                                        position: 'absolute', top: '100%', width: '108%', left: '-25px', backgroundColor: '#fff',
                                        border: '1px solid #ccc', borderRadius: '0', zIndex: 1000, boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
                                    }}>

                                        {Object.keys(sortingOptions).map((item) => (
                                            <div
                                                key={item}
                                                onClick={() => handleSortOrderChange(item)}
                                                style={{
                                                    padding: '10px 20px',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: sortOrder === item ? '#e0e0e0' : '#fff', // Highlight the selected item
                                                    // backgroundColor: sortOrder.column === sortingOptions[item].column && sortOrder[0].type === sortingOptions[item].type ? '#e0e0e0' : '#fff',
                                                }}
                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = sortOrder === item ? '#e0e0e0' : '#fff'} // Revert to original

                                            >
                                                {item} {sortOrder === item && <FaCheck />}

                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>



                        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', maxHeight: '88vh', scrollbarWidth: 'thin', scrollbarColor: 'white' }} onScroll={handleBroadcastScroll}>
                            {items.map((item) => (
                                <div
                                    // key={index}
                                    key={item.id}
                                    // onClick={() => { setSearchTermParticipant(''); handleItemClick(item.name, item.members, item.id); }}
                                    onClick={() => {
                                        if (selectedItem !== item.name) { // Prevent clicking if already selected
                                            setSearchTermParticipant('');
                                            handleItemClick(item.name, item.members, item.id);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderBottom: '0.5px solid #e0e0e0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: selectedItem === item.name ? '#e6f7ff' : 'transparent'

                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        backgroundColor: '#ddd',
                                        marginRight: '10px',
                                        color: 'cadetblue',
                                        fontSize: '1.5rem',
                                        flexShrink: 0,
                                    }}>
                                        <MdGroups />
                                    </div>
                                    <div style={{ wordBreak: 'break-word' }}>
                                        {item.name}
                                        <br />
                                        <span style={{ fontSize: 'small', color: '#666' }}>{item.members} members</span>
                                    </div>
                                </div>
                            ))}

                            {showNewListModal && (
                                <div
                                    onClick={handleCloseNewListModal}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 1000,
                                    }}
                                >
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            width: '90%',
                                            maxWidth: '90%',
                                            // height: 'calc(100vw * 0.5)', // Maintain a 4:3 aspect ratio
                                            maxHeight: '50vh',
                                            backgroundColor: 'white',
                                            borderRadius: '0.3rem',
                                            boxShadow: '0 3px 9px rgba(0,0,0,.5)',
                                            // zIndex: 1000, // Ensure the modal is above the background
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0.5rem 1rem',
                                                borderBottom: '1px solid #dee2e6'
                                            }}
                                        >
                                            <h5 style={{ fontSize: 'large', margin: 0 }}>Create a new broadcast</h5>
                                            <button
                                                type="button"
                                                onClick={handleCloseNewListModal}
                                                style={{
                                                    border: 'none',
                                                    background: 'none',
                                                    fontSize: '1.5rem',
                                                    lineHeight: '1',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        <div style={{ padding: '1rem' }}>
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div style={{ marginBottom: '1rem' }}>
                                                    <label htmlFor="formListName" style={{ fontSize: '11px', color: '#6c757d', }}>Broadcast Name</label>
                                                    <input
                                                        type="text"
                                                        id="formListName"
                                                        placeholder="Enter broadcast name"
                                                        onChange={handleNewListInputChange}
                                                        value={listName}
                                                        style={{
                                                            width: '100%',
                                                            border: 'none',
                                                            borderBottom: '1px solid #ccc',
                                                            borderRadius: '0',
                                                            padding: '0.5rem 0',
                                                            outline: 'none',
                                                            background: 'none'
                                                        }}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                padding: '0.5rem 1rem',
                                                borderTop: '1px solid #dee2e6'
                                            }}
                                        >
                                            <button
                                                type="button"
                                                onClick={handleCloseNewListModal}
                                                style={{
                                                    color: '#6c757d',
                                                    marginRight: '1rem',
                                                    border: 'none',
                                                    fontWeight: '500',
                                                    backgroundColor: 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCreateNewList}
                                                disabled={!listName && !isCreating}
                                                style={{
                                                    color: listName ? '#3F906D' : '#6c757d',

                                                    border: 'none',
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'transparent',
                                                    cursor: listName ? 'pointer' : 'default',
                                                    opacity: listName ? 1 : 0.5
                                                }}
                                            >
                                                Create List
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}


                            {/* new list button */}
                            <div style={{ position: 'absolute', bottom: '3%', width: '40%', display: 'flex', right: '20px', justifyContent: 'flex-end', }}>
                                <div style={{ width: '95%', maxWidth: '20rem', position: 'relative' }}>
                                    <Button
                                        style={{
                                            width: '100%',
                                            padding: '0.5rem',
                                            backgroundColor: '#3F906D',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '3px',
                                            cursor: showNewListModal ? 'default' : 'pointer',
                                            fontWeight: 'bold',
                                            pointerEvents: showNewListModal ? 'none' : 'auto' // Disable button when modal is active
                                        }}
                                        onClick={handleNewListClick}
                                    >

                                        <MdGroups style={{ marginRight: '10px', fontSize: '1.5rem' }} />
                                        New List
                                    </Button>
                                </div>
                            </div>

                        </div>

                    </div></div>
                </Col>

                {showBroadcastDetails && <Col xs={7} style={{ padding: 0, border: '1px solid #E5E4E2', height: 'calc(100vh - 58px)' }}>
                    <div style={{ height: '58px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '15px', marginBottom: '4px', fontWeight: '500', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {selectedItem}
                            <div style={{ fontSize: '12px', color: '#6c757d', }}>{selectedmembers} members</div>
                        </div>
                        <div style={{ width: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                            {/* <Button onClick={handleAddParticipantClick} style={{ marginRight: '10px', fontSize: '0.875rem', padding: '5px 10px', fontWeight: 'bold', color: '#212529', backgroundColor: isHovered ? '#f9f9f9' : 'white', borderColor: '#E5E4E2' }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                Manage Access
                            </Button> */}
                            <div
                                style={{
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    backgroundColor: isDeleteHovered ? '#f9f9f9' : 'transparent',
                                    width: '45px',
                                    height: '45px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'

                                }}
                                onMouseEnter={() => setIsDeleteHovered(true)}
                                onMouseLeave={() => setIsDeleteHovered(false)}

                                onClick={() => { setShowDeletePopup(true) }}
                            >
                                <RiDeleteBin6Line style={{ color: isDeleteHovered ? 'red' : 'black', fontSize: '1rem' }} />
                            </div>
                        </div>
                    </div>



                    <div style={{ position: 'relative' }}>


                        {!typeMessageImage && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100vh - 190px)', opacity: 0.5, backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', zIndex: 1 }}></div>}

                        <div style={{ position: 'relative', height: 'calc(100vh - 190px)', zIndex: 99 }}>
                            {cliploading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <ClipLoader color="#176433" size={60} />
                                </div>
                            ) : (
                                (selectedItemId && !typeMessageImage) && (

                                    <div
                                        ref={chatContainerRef}
                                        style={{ overflowY: 'scroll', height: '100%', scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1', padding: 10 }}
                                        onScroll={handleChatScroll}
                                    >
                                        {chatMessagesTemp.map((message, index) => (
                                            <div key={index} style={{ textAlign: message.message_nature === "Outgoing" ? 'right' : 'left', marginBottom: '10px' }}>
                                                <div style={{ display: 'inline-block', maxWidth: '80%', padding: '8px', borderRadius: '10px', backgroundColor: message.message_nature === 'Outgoing' ? '#E5FFEF' : '#FFFFFF', boxShadow: '0 1px 2px rgba(0,0,0,0.2)' }}>
                                                    {message.message_type === 'broadcast' && message.template_image_path && (
                                                        // <img src={message?.template_image_path} alt="templateImage" height="auto" style={{ borderRadius: '5px', maxHeight: '400px', maxWidth: '100%', width: '300px' }} />
                                                        <img src={message?.template_image_path} alt="templateImage" height="auto" style={{ borderRadius: '5px', maxHeight: '400px', maxWidth: '100%', width: '300px' }} />

                                                    )}

                                                    <div style={{ textAlign: 'left', maxWidth: '300px', height: 'auto' }}>
                                                        <pre style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', whiteSpace: 'pre-wrap', width: '100%', wordWrap: 'break-word' }}>
                                                            {/* <div style={{ padding: '5px', width: '100%', wordWrap: 'break-word' }}> */}


                                                            {message.whatsapp_message && message.message_type === 'broadcast'
                                                                ? message.whatsapp_message?.startsWith('"') && message.whatsapp_message.endsWith('"')
                                                                    ? message.whatsapp_message?.slice(1, -1).replace(/\\r\\n|\\n|\\r/g, '\n')
                                                                    : message.whatsapp_message?.replace(/\\r\\n|\\n|\\r/g, '\n')
                                                                : message.whatsapp_message?.replace(/\\r\\n|\\n|\\r/g, '\n')}
                                                        </pre>
                                                    </div>

                                                    <div style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        display: "flex",
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}>
                                                        {(message.url_btn_name) && <div style={{
                                                            width: '105%',
                                                            padding: 2,
                                                            justifyContent: 'center',
                                                            fontWeight: 'bold',
                                                            display: 'flex',
                                                            paddingBottom: '5px',
                                                            color: '#00BFFF',
                                                            borderTop: '1px solid #ccc',
                                                            alignItems: 'center'
                                                        }}>
                                                            <a href={`https://${message.url_link}`} target='_blank' style={{
                                                                width: '100%',
                                                                textDecoration: 'none',
                                                                padding: 2,
                                                                justifyContent: 'center',
                                                                fontWeight: 'bold',
                                                                display: 'flex',
                                                                paddingBottom: '5px',
                                                                color: '#00BFFF',
                                                                alignItems: 'center'
                                                            }}> <VscLinkExternal style={{ marginRight: '5px' }} />{message.url_btn_name} </a>
                                                        </div>}
                                                        {(message.btn_name) && <div style={{
                                                            width: '105%',
                                                            padding: 2,
                                                            justifyContent: 'center',
                                                            fontWeight: 'bold',
                                                            padding: '5px',
                                                            display: 'flex',
                                                            marginBottom: '10px',
                                                            borderTop: '1px solid #ccc',
                                                            color: '#00BFFF',
                                                            alignItems: 'center'
                                                        }}>
                                                            <MdLocalPhone style={{ marginRight: '5px' }} />{message.btn_name}
                                                        </div>}
                                                    </div>

                                                    <div style={{ textAlign: 'right', maxWidth: '300px' }}>
                                                        <p style={{ fontSize: '12px', color: '#888', margin: '5px 0', marginTop: '5px' }}>{showChatDateTime(message.updated_at)}

                                                            {/* {message.message_nature === "Outgoing" && (
                                                                message.status === 'read' ? (
                                                                    <RiCheckDoubleFill style={{ marginLeft: '5px', fontSize: '14px', color: 'rgb(0, 191, 255)' }} />
                                                                ) : message.status === 'delivered' ? (
                                                                    <RiCheckDoubleFill style={{ marginLeft: '5px', fontSize: '14px', color: 'grey' }} />
                                                                ) : (message.status === 'accepted' || message.status === 'sent') ? (
                                                                    <RiCheckFill style={{ marginLeft: '5px', fontSize: '14px', color: 'grey' }} />
                                                                ) : (
                                                                    ''
                                                                )
                                                            )} */}

                                                        </p>
                                                    </div>


                                                </div>
                                            </div>
                                        ))}

                                    </div>))}
                        </div>
                    </div>


                    <div style={{ position: 'fixed', width: '100%', marginLeft: '18px', marginTop: '10px', zIndex: 9999 }}>
                        <Button variant="success" onClick={handleSendTemplateClick} style={{ display: 'flex', alignItems: 'center', color: 'white' }} disabled={sendTemplateBtnDisable()}>
                            <LuSend style={{ marginRight: '5px', color: 'white' }} />
                            Send Template
                        </Button>
                        {isPopupVisible && <TemplatePopup onClose={handleClosePopup} broadcast_id={selectedItemId} tempRefresh={handleTempRefresh} />}
                    </div>



                </Col>}

                {showBroadcastDetails && <Col xs={2} className="column" style={{ ...columnStyle, height: 'calc(100vh - 58px)', position: 'relative' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', borderBottom: '1px solid #E5E4E2', height: '58px' }}>
                            {isSearchVisible ? (
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingRight: '5px', padding: '6px' }}>
                                    <input
                                        type="text"
                                        placeholder="Search segments"
                                        style={{ width: '100%', marginRight: '10px', border: 'transparent', padding: '5px', outline: 'none' }}
                                        value={searchTermParticipant}
                                        onChange={(e) => {
                                            setSearchTermParticipant(e.target.value);
                                            getParticipantSearch(e.target.value)
                                        }
                                        }
                                    />
                                    <div onClick={handleSearchClick} style={{ cursor: 'pointer', fontSize: '1.5rem', marginLeft: '5px' }}>
                                        &times;
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div onClick={handleAddParticipantsClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', flexDirection: 'row' }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '35px',
                                            height: '35px',
                                            borderRadius: '50%',
                                            backgroundColor: '#3F906D',
                                            marginRight: '10px',
                                            color: 'white',
                                            fontSize: '1.5rem'
                                        }}>
                                            <HiUserAdd />
                                        </div>
                                        <div style={{ fontWeight: 'bold' }}>Add Participants</div>
                                    </div>
                                    <IoMdSearch onClick={handleSearchClick} style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.4rem' }} />
                                </>
                            )}
                        </div>

                        <div style={{ flex: 1, overflowY: 'scroll', maxHeight: 'calc(100% - 15%)', scrollbarWidth: 'thin', scrollbarColor: 'white' }} onScroll={handleParticipantScroll} >

                            {participants.length > 0 ? (
                                participants.map((participant, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleShowContact(participant)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '10px',
                                            borderBottom: '0.5px solid #e0e0e0',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: 'grey',
                                            // backgroundColor: getColorForName(participant.first_name),
                                            marginRight: '10px',
                                            color: 'white',
                                            fontSize: '1.5rem',
                                            flexShrink: 0,
                                            lineHeight: '1',


                                        }}>
                                            {participant.first_name && typeof participant.first_name === 'string' && participant.first_name.length > 0 && /^[a-zA-Z]/.test(participant.first_name[0])
                                                ? participant.first_name[0].toUpperCase()
                                                : participant.mobile && typeof participant.mobile === 'string' && participant.mobile.length > 0
                                                    ? participant.mobile[0]
                                                    : '?'}
                                        </div>
                                        <div style={{ overflow: 'hidden', wordWrap: 'break-word' }} >
                                            {participant.first_name} {participant.last_name}
                                            <br />
                                            <span style={{ fontSize: 'small', color: '#666' }}>{participant.mobile}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div style={{ paddingLeft: '5px' }}>No participants found</div>
                            )}
                        </div>

                        {showContact && (
                            <div
                                onClick={handleCloseContact}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1000,
                                }}
                            >
                                <div
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        width: '80%',
                                        maxWidth: '80%',
                                        maxHeight: '50vh',
                                        backgroundColor: 'white',
                                        borderRadius: '0.3rem',
                                        boxShadow: '0 3px 9px rgba(0,0,0,.5)',
                                        padding: '10px 0',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {selectedContact && (
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => handleContactMessage(selectedContact)}
                                                style={{
                                                    color: '#6c757d',
                                                    border: 'none',
                                                    fontWeight: '500',
                                                    backgroundColor: 'transparent',
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #dee2e6',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingBottom: '8px',
                                                    width: '100%',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div><BsSend style={{ marginRight: '8px' }} />Message</div>
                                                    <div style={{ fontWeight: 'bold', color: 'black', marginLeft: '10px' }}>{selectedContact.first_name} {selectedContact.last_name}</div>
                                                </div>
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleContactRemove(selectedContact.id)}
                                                style={{
                                                    color: '#6c757d',
                                                    border: 'none',
                                                    fontWeight: '500',
                                                    backgroundColor: 'transparent',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div><span style={{ fontSize: '1.5rem', marginRight: '8px' }}>&times;</span>Remove</div>
                                                    <div style={{ fontWeight: 'bold', color: 'black', marginLeft: '10px' }}>{selectedContact.first_name} {selectedContact.last_name}</div>
                                                </div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Col>}


            </Row>


            <Modal show={showAddParticipant} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold', fontSize: '16px' }}>Add Member to List</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group controlId="formParticipantFirstName">
                        <Form.Label style={{ fontSize: '11px' }}>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={firstName}
                            maxLength={50}
                            // onChange={(e) => setFirstName(e.target.value)}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!/^\s/.test(value) && /^[a-zA-Z\s]*$/.test(value)) {
                                    setFirstName(value);
                                }
                            }}
                            style={{ border: 'none', borderBottom: '1px solid black', borderRadius: '0', padding: '0', marginBottom: '1rem' }}
                            placeholder="Enter first name"
                        />
                    </Form.Group>

                    <Form.Group controlId="formParticipantLastName">
                        <Form.Label style={{ fontSize: '11px' }}>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={lastName}
                            maxLength={50}
                            // onChange={(e) => setLastName(e.target.value)}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!/^\s/.test(value) && /^[a-zA-Z\s]*$/.test(value)) {
                                    setLastName(value);
                                }
                            }}
                            style={{ border: 'none', borderBottom: '1px solid black', borderRadius: '0', padding: '0', marginBottom: '1rem' }}
                            placeholder="Enter last name"
                        />
                    </Form.Group>

                    <div>
                        <Form.Label style={{ fontSize: '11px', color: '#3F906D' }}>Phone Number</Form.Label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Dropdown onSelect={(key) => handleCountryChange(countryData.find(country => country.code === key))}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: 'aliceblue', color: 'black', borderColor: '#3F906D', padding: '1px', width: '60px' }}>
                                {selectedCountry.dial_code}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '100px', width: '2rem', overflowY: 'auto' }}>
                                {countryData.map((country, index) => (
                                    <Dropdown.Item key={index} eventKey={country.code} style={{ height: '20px', display: 'flex', paddingLeft: '5px', justifyContent: 'left' }}>
                                        {country.name} ({country.dial_code})
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Form.Control
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyPress={handleKeyPress}
                            maxLength={10}
                            style={{
                                border: 'none',
                                borderBottom: '1px solid black',
                                borderRadius: '0',
                                padding: '0',
                                marginBottom: '0',
                                width: '100%',
                                marginLeft: '10px'
                            }}
                            placeholder="Enter phone number"
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="Success" onClick={handleCloseModal} style={{ color: '#6c757d', marginRight: '2rem', border: 'none', fontWeight: 'bold' }}>
                        Cancel
                    </Button>
                    <Button
                        variant="Success"
                        onClick={addParticipant}
                        // disabled={!(firstName && phone.length >= 10 && /^[6-9]/.test(phone))}
                        disabled={!(firstName && /^[a-zA-Z][a-zA-Z\s]*$/.test(firstName) && phone.length === 10 && /^[6-9]/.test(phone))}
                        style={{ color: '#3F906D', border: 'none', fontWeight: 'bold', marginRight: '1rem' }}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Popup */}

            <Modal show={showDeletePopup} onHide={() => { setShowDeletePopup(false) }} centered>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>Are you sure you want to delete this broadcast?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ color: "#6c757d", backgroundColor: 'transparent', border: 'none' }} onClick={() => { setShowDeletePopup(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>



            {isImportOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '999'
                    }}
                    onClick={toggleImportPopup}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '4px',
                            width: '500px',
                            height: '300px',
                            textAlign: 'center',
                            position: 'relative',

                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <h6 style={{ margin: 0 }}>Import Customers</h6>
                            <button
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={toggleImportPopup}
                            >
                                &times;
                            </button>
                        </div>

                        {!selectedFileImport ? (
                            <>
                                <div style={{ marginBottom: '15px' }}>
                                    <RiFileExcel2Fill style={{ width: '50px', height: '50px' }} />
                                </div>
                                <p style={{ fontWeight: 'bold', color: '#495057' }}>
                                    Please choose an Excel sheet.
                                </p>
                                <p
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        color: 'rgb(122, 122, 122)',
                                        marginTop: '10px',
                                    }}
                                >
                                    Only .xlsx file allowed.
                                </p>
                                <button
                                    style={{
                                        backgroundColor: '#198754',
                                        color: '#fff',
                                        padding: '10px 20px',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        marginTop: '20px',
                                    }}
                                    onClick={handleDropdownItemClick}
                                >
                                    Browse
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileInputChange}
                                />
                            </>
                        ) : (
                            <>
                                <p>{selectedFileImport}</p>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '20px',
                                        maxWidth: '450px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <button
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'Black',
                                            padding: '10px 20px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={handleDismissClick}
                                    >
                                        Dismiss
                                    </button>
                                    <button
                                        style={{
                                            backgroundColor: '#198754',
                                            color: '#fff',
                                            padding: '10px 20px',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={handleNextClick}
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {isFileMapOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '999'
                    }}
                    onClick={() => setIsFileMapOpen(false)}
                >
                    <div
                        style={{
                            width: '450px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                            padding: '10px',
                            fontFamily: 'Arial, sans-serif',
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                borderBottom: '1px solid #ddd',
                                paddingBottom: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            <span>Import Customers - Map Columns</span>
                        </div>
                        <div
                            style={{
                                textAlign: 'left',
                                borderBottom: '1px solid #ddd',
                                paddingBottom: '1px',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span>Fields</span>
                            <span>Excel Columns</span>
                        </div>
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                            }}
                        >
                            {[
                                { label: 'First Name', name: 'firstName', required: true },
                                { label: 'Last Name', name: 'lastName', required: false },
                                // { label: 'Phone Number', name: 'phoneNumber', required: true },
                                { label: 'Mobile', name: 'phoneNumber', required: true },
                            ].map((field, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <label style={{ flex: 1 }}>
                                        {field.label}
                                        {field.required && <span style={{ color: 'red' }}>*</span>}
                                    </label>
                                    <select
                                        name={field.name}
                                        // value={formDataImport[field.name] || ''}
                                        value={formDataImport[field.name]?.selectedColumn || ''}
                                        onChange={handleChange}
                                        style={{
                                            flex: 1,
                                            padding: '4px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                        }}
                                    >
                                        {/* <option value="">Select a column</option> */}
                                        {!formDataImport[field.name]?.selectedColumn && (
                                            <option value="">Select a column</option>
                                        )}
                                        {columnNames?.map((col, idx) => (
                                            <option key={idx} value={col}>
                                                {col}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    padding: '10px 20px',
                                    borderRadius: '4px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => setIsFileMapOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                style={{
                                    // backgroundColor: 'rgb(200, 200, 200)',
                                    backgroundColor: isSubmitDisabled ? 'rgb(200, 200, 200)' : '#198754',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '4px',
                                    border: 'none',
                                    cursor: isSubmitDisabled ? 'default' : 'pointer',
                                    fontWeight: 'bold',
                                }}
                                onClick={handleSubmit}
                                disabled={isSubmitDisabled}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}



            <SnackbarProvider style={{ marginTop: '15%' }} />

        </Container>


    )
}

export default Broadcast