import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE, LEAD_ASSIGN_MODE, REDIRECT_URL } from './ResponseHandler';
import { EMP_USER_ID, RIGHTS, responseHandler } from "./ResponseHandler";

import { BASE_URL } from "./Url";
import { getHeader } from "./Header";

import Cookies from "js-cookie";
// import { Navigate, Route } from 'react-router-dom';
// import RightsMaster from 'layouts/master-data/rights-master/rights-master';

/**
* Main Api Caller is responsible for send request to
* server and receive response from server
* Every Api call should be made by using this function.
*
* @param {string} urls
* @param {object} model
* @param {boolean} requestType
* @param {boolean} showSuccess
* @param {boolean} multiPart [if true the model should be send from the response of CreateFormData.js]
*
* @returns {any} data
*

*/
export default async (urls, model, requestType, showSuccess = false, multiPart = false, clientId = false) => {
  var data = false;
  const headers = await getHeader(multiPart, clientId);
  var params = {
    method: requestType,
    mode: "cors",
    redentials: "include",
    withCredentials: true,
    crossDomain: true,
    headers: headers,
  };

  if (model) {
    params["body"] = multiPart ? model : JSON.stringify(model);
  }

  try {


    const response = await fetch(BASE_URL + urls, params);
    // if(response.statusText == 'Unauthorized'){

    //   if(clientId){
    //     let adminUserType = Cookies.get(LOGIN_USERTYPE_COOKIE);
    //     let adminClientId = adminUserType == '1' || adminUserType == 1 ? "" : clientId;
    //      Cookies.remove(LOGIN_AUTH_COOKIE+adminClientId);
    //     Cookies.remove(LOGIN_REFRESH_COOKIE+adminClientId);
    //     Cookies.remove(LOGIN_USERTYPE_COOKIE+adminClientId);
    //     window.location.href ="/client/login";
    //   }else{
    //     Cookies.remove(LOGIN_AUTH_COOKIE);
    //     Cookies.remove(LOGIN_REFRESH_COOKIE);
    //     Cookies.remove(LOGIN_USERTYPE_COOKIE);
    //     window.location.href ="/login";
    //   }

    // } 

    const responseJson = await response.json();
    const status = await responseHandler(responseJson, showSuccess);
    if (responseJson.message === "Token is not valid") {
      // alert(responseJson.message)
      Cookies.remove(LOGIN_AUTH_COOKIE);
      Cookies.remove(LOGIN_REFRESH_COOKIE);
      Cookies.remove(RIGHTS);
      Cookies.remove(EMP_USER_ID);
      Cookies.remove(LEAD_ASSIGN_MODE);
      // window.location.href = "/authentication/sign-in";
      window.location.href = REDIRECT_URL;
    }

    if (responseJson.message === "You have been logged in other browser too.") {
      //alert(responseJson.message)
      Cookies.remove(LOGIN_AUTH_COOKIE);
      Cookies.remove(LOGIN_REFRESH_COOKIE);
      Cookies.remove(RIGHTS);
      Cookies.remove(EMP_USER_ID);
      Cookies.remove(LEAD_ASSIGN_MODE);
      // window.location.href = "/authentication/sign-in";
      window.location.href = REDIRECT_URL;

    }

    if (responseJson.message === "Your session has been expired!") {
      //alert(responseJson.message)
      Cookies.remove(LOGIN_AUTH_COOKIE);
      Cookies.remove(LOGIN_REFRESH_COOKIE);
      Cookies.remove(RIGHTS);
      Cookies.remove(EMP_USER_ID);
      Cookies.remove(LEAD_ASSIGN_MODE);
      // window.location.href = "/authentication/sign-in";
      window.location.href = REDIRECT_URL;
    }

    data = responseJson;
    if (status) {
      data = responseJson;//responseJson.data;
    }
    else if (!status && responseJson && responseJson.data && responseJson.data.username[0]) {
      alert("Username already exists")
      //return responseJson;
    }

  }
  catch (error) {

  }

  return data;
};
