import React, { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormControl, Form, Button } from 'react-bootstrap';

import { IoSearchOutline } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";

import { FaEllipsisV, FaLongArrowAltUp } from 'react-icons/fa';

import { FiSend } from "react-icons/fi";
// import backgroundImage from '../images/backgroudImage.jpeg'

import { LuMailPlus } from "react-icons/lu";
import Dropdown from 'react-bootstrap/Dropdown';
import CreateTemplate from './CreateTemplate';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTemplateFromTable_v1, deleteTemplate_v1 } from '../../Apis/config/Url';
import { GET, POST } from '../../Apis/config/RequestType';
import Caller from '../../Apis/config/Caller';
import { VscLinkExternal } from "react-icons/vsc";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineEmail, MdLocalPhone } from "react-icons/md";
import { RiContactsBookLine } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { ClipLoader, HashLoader, PuffLoader } from 'react-spinners';
import moment from 'moment';
import Cookies from 'js-cookie';



const Templates = () => {
    const [templateStatus, setTemplateStatus] = useState("ALL");
    const navigate = useNavigate();
    const [templatePage, setTemplatePage] = useState(1);
    const templateDivRef = useRef(null);
    const [totalTemplatePages, setTotalTemplatePages] = useState(null);
    const [templatesDetails, setTemplatesDetails] = useState([])
    const [search, setSearch] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectedTemplateID, setSelectedTemplateID] = useState('')
    const [selectedTemplateName, setSelectedTemplateName] = useState('')
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const newTemplate = location.state?.newTemplate;

    useEffect(() => {
        if (newTemplate) {
            setTemplatesDetails([newTemplate, ...templatesDetails]);
            // window.location.reload();
        }
    }, [newTemplate]);


    useEffect(() => {
        const fetchTemplate = async () => {
            try {

                const getTemplate = await Caller(`${getTemplateFromTable_v1}?page=${Number(templatePage)}`, '', GET, false, false);

                setTemplatesDetails(getTemplate.data.data)
                setTotalTemplatePages(getTemplate.data.totalPages);
            } catch (error) {
                console.error('Error fetching template details:', error);
            }
        };

        fetchTemplate();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (templateDivRef.current) {
                templateDivRef.current.scrollTop = 0;
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const templateStatusList = [
        { value: 'ALL', label: 'All' },
        { value: 'APPROVED', label: 'Approved' },
        // { value: 'IN REVIEW', label: 'Pending' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'REJECTED', label: 'Rejected' },
    ];


    const handleSelect = (eventKey) => {
        setTemplateStatus(eventKey);
        handleTemplateSearch({ target: { value: searchQuery, status: eventKey } });
    };

    const handleTemplateScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        // console.log("Scroll bottom:", Math.floor(scrollBottom));

        if (Math.floor(scrollBottom) <= 0 && templatePage < totalTemplatePages) {
            // setTemplatePage(prevPage => prevPage + 1); 
            const nextPage = templatePage + 1;
            setTemplatePage(nextPage);


            const params = new URLSearchParams();

            if (searchQuery) {
                params.append('s', searchQuery);
            }

            if (templateStatus !== 'ALL') {
                if (templateStatus === 'PENDING') {
                    params.append('status', 'IN REVIEW');
                } else {
                    params.append('status', templateStatus);
                }
            }

            // params.append('page', templatePage + 1);
            params.append('page', nextPage);


            try {
                const getTemplate = await Caller(`${getTemplateFromTable_v1}?${params.toString()}`, '', GET, false, false);

                setTemplatesDetails(prevTemp => [...prevTemp, ...getTemplate.data.data]);
            } catch (error) {
                console.error('Error fetching additional template details:', error);
            }
        }
    };


    const handleClearSearch = () => {
        setSearchQuery('');
        setSearch(false)
        handleTemplateSearch({ target: { value: setSearchQuery('') } })

    }

    const handleTemplateSearch = async ({ target }) => {
        const { value, status } = target;
        const searchValue = value
        const selectedStatus = status || templateStatus;

        setSearchQuery(searchValue);
        const params = new URLSearchParams();
        setTemplatePage(1);
        // setTemplatesDetails([]);
        try {
            if (searchValue) {
                params.append('s', searchValue);
            }

            if (selectedStatus !== 'ALL') {
                if (selectedStatus === 'PENDING') {
                    params.append('status', 'IN REVIEW');
                } else {
                    params.append('status', selectedStatus);
                }
            }

            if (searchValue || selectedStatus) {
                const getTemplate = await Caller(`${getTemplateFromTable_v1}?${params.toString()}`, '', GET, false, false);
                setTemplatesDetails(getTemplate.data.data);
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
        }
    };


    const openDeleteModal = (templateId, templateName) => {
        setSelectedTemplateID(templateId);
        setSelectedTemplateName(templateName);
        setShowDeletePopup(true);
    };

    const handleDeleteClick = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("id", selectedTemplateID);
        formData.append("template_name", selectedTemplateName);
        const delete_template = await Caller(`${deleteTemplate_v1}`, formData, 'POST', false, true)

        if (delete_template.success) {
            setLoading(false)
            setTemplatesDetails(prevTemplates => prevTemplates.filter(template => template.id !== selectedTemplateID));
            enqueueSnackbar('Template Deleted Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            // setShowBroadcastDetails(false)
            setShowDeletePopup(false)
        }
        else {
            console.log('Template not deleted')
        }


    }

    const isSameDate = (updatedAt) => {
        const today = moment().format('YYYY-MM-DD');
        const templateUpdatedAt = moment(updatedAt).format('YYYY-MM-DD');

        return moment(today).isSame(templateUpdatedAt, 'day');
    };

    return (
        <Container fluid style={{ overflowY: 'hidden', height: 'calc(100vh - 58px)', border: '1px solid #e5e4e2' }}>
            <Row style={{ border: '1px solid #E5E4E2', marginLeft: '37px', overflowY: 'hidden', height: 'calc(100vh - 58px)', }}>
                <Col style={{ padding: 0, border: '1px solid #E5E4E2' }}>

                    {/* <div style={{ height: '60px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {search && (
                                <div style={{ position: 'absolute', width: '75%', height: '120px', color: '#c2c2c2' }}>
                                    <input
                                        type="text"
                                        placeholder="Enter Search term"
                                        // value={searchQuery}
                                        // onChange={handleTemplateSearch}
                                        style={{ width: '100%', height: '30px', border: 'none', outline: 'none', boxShadow: 'none' }}

                                    />
                                    <FaTimes
                                        style={{ color: '#3F906D', cursor: 'pointer', position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}
                                        onClick={handleClearSearch}
                                    />
                                </div>
                            )}
                        
                        {!search && <div>
                            <h6>Templates</h6>
                        </div>}
                        <div>
                        {!search && <IoSearchOutline style={{ color: '#3F906D' }} size={25} onClick={()=>{setSearch(true)}}/>}
                            <Button style={{ marginLeft: '20px', backgroundColor: '#3F906D', borderColor: '#fbfbfc', color: '#FFF', border: ' 0.5px solid gray', borderRadius: '5px' }} onClick={() => navigate('/create_template')}>
                                <LuMailPlus style={{ color: '#FFF' }} size={20} /> New Template
                            </Button>
                        </div>
                    </div> */}

                    <div style={{ height: '60px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        {search ? (
                            <div style={{ flex: 1, position: 'relative' }}>
                                <input
                                    type="text"
                                    placeholder="Enter Search term"
                                    value={searchQuery}
                                    onChange={handleTemplateSearch}
                                    style={{ width: '100%', height: '30px', border: 'none', outline: 'none', boxShadow: 'none' }}
                                />
                                <FaTimes
                                    style={{ color: '#3F906D', cursor: 'pointer', position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                    onClick={handleClearSearch}
                                />
                            </div>
                        ) : (
                            <div>
                                <h6>Templates</h6>
                            </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {!search && (
                                <IoSearchOutline style={{ color: '#3F906D', cursor: 'pointer' }} size={25} onClick={() => setSearch(true)} />
                            )}
                            <Button style={{ marginLeft: '20px', backgroundColor: '#3F906D', borderColor: '#fbfbfc', color: '#FFF', border: ' 0.5px solid gray', borderRadius: '5px' }} onClick={() => navigate('/create_template')}>
                                <LuMailPlus style={{ color: '#FFF' }} size={20} /> New Template
                            </Button>
                        </div>
                    </div>


                    <div style={{ width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <div>
                            <h6>Template Status</h6>
                        </div>
                        <div>
                            <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {templateStatus}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {templateStatusList.map(status => (
                                        <Dropdown.Item key={status.value} eventKey={status.value}  >
                                            {status.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>


                    <div style={{ flex: 1, width: '100%', overflowY: 'auto', scrollbarWidth: 'thin', msOverflowStyle: 'thin', height: 'calc(100vh - 200px)' }} ref={templateDivRef} onScroll={handleTemplateScroll}>

                        {loading && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
                            zIndex: 9999, // higher than other elements
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HashLoader color="#176433" size={100} />
                        </div>}
                        {templatesDetails.map((template, index) => (
                            <div key={index} style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', marginLeft: '20px' }}>
                                    <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.template_name}</div>
                                    <span style={{ color: '#a19f9f' }}>•</span>
                                    <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.status === "IN REVIEW" ? "PENDING" : template.status}</div>
                                    <span style={{ color: '#a19f9f' }}>•</span>
                                    <div style={{ fontWeight: 'bold', color: '#a19f9f' }}>{`Created By ${template.user_details}`}</div>
                                </div>
                                <div style={{ padding: '10px', width: '50%', height: 'auto' }}>
                                    <div style={{ padding: '10px', width: '50%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ border: '3px solid #E5E4E2', borderRadius: '5px', height: '20%', width: '100%' }}>
                                            {/* {template.image ? (<img src={template.image} alt="Description of image" style={{ width: '100%', height: 'auto', padding: 0 }} />) : ('')}
                                            {template.description} */}

                                            {template.image_path && <div>
                                                {/* <img src={`${template?.image_url}`} alt="Description of image" style={{ */}
                                                <img src={template.image_path} style={{
                                                    width: '100%', height: '80%', padding: 0, borderTopLeftRadius: '7px',
                                                    borderTopRightRadius: '7px'
                                                }} />
                                            </div>}
                                            <div>
                                                <div style={{ padding: '5px', width: '100%', wordWrap: 'break-word' }}>
                                                    {template.body ? template.body : ""}
                                                </div>

                                                <div style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    display: "flex",
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    {(template.url_btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        display: 'flex',
                                                        paddingBottom: '5px',
                                                        color: '#00BFFF',
                                                        borderTop: '1px solid #ccc',
                                                        alignItems: 'center'
                                                    }}>
                                                        <a href={`https://${template.url_link}`} target='_blank' rel='noopener noreferrer' style={{

                                                            textDecoration: 'none',
                                                            padding: 2,
                                                            justifyContent: 'center',
                                                            fontWeight: 'bold',
                                                            display: 'flex',
                                                            paddingBottom: '5px',
                                                            color: '#00BFFF',
                                                            alignItems: 'center'
                                                        }}>
                                                            <VscLinkExternal style={{ marginRight: '5px' }} />{template.url_btn_name}</a>
                                                    </div>}
                                                    {(template.btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        marginBottom: '10px',
                                                        borderTop: '1px solid #ccc',
                                                        color: '#00BFFF',
                                                        alignItems: 'center'
                                                    }}>
                                                        <MdLocalPhone style={{ marginRight: '5px' }} />{template.btn_name}
                                                    </div>}
                                                </div>
                                            </div>


                                        </div>
                                        {((Cookies.get('auth_usertype') != '4')) && (<div>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                                    <FaEllipsisV className="icon" style={{ color: 'black' }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item href="#/action-1" style={{ borderBottom: '1px solid #E5E4E2' }}>Send Via API</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2" style={{ borderBottom: '1px solid #E5E4E2' }}>Duplicate</Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => openDeleteModal(template.id, template.template_name)}>Delete</Dropdown.Item>
                                                    <Dropdown.Item disabled={isSameDate(template.updated_at)} onClick={() => { navigate('/create_template', { state: { templateDetails: template } }) }}>Edit</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>)}
                                    </div>
                                </div>
                                {index < templatesDetails.length - 1 && <hr style={{ border: '1px solid #E5E4E2', width: '100%', margin: '10px 0' }} />}
                            </div>
                        ))}
                    </div>

                    {/* Delete Popup */}

                    <Modal show={showDeletePopup} onHide={() => { setShowDeletePopup(false) }} centered>
                        <Modal.Body>Are you sure you want to delete this Template?</Modal.Body>
                        <Modal.Footer>
                            <Button style={{ color: "#6c757d", backgroundColor: 'transparent', border: 'none' }} onClick={() => { setShowDeletePopup(false) }}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => handleDeleteClick()}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </Col>

            </Row>
            <SnackbarProvider style={{ marginTop: '15%' }} />
        </Container >
    )
}

export default Templates