import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import { BsSlashLg } from 'react-icons/bs';
import { RxButton } from "react-icons/rx";
import { FaArrowLeft } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { RiDeleteBin5Line } from "react-icons/ri";

let dummyRepies = [
  {
    id: 1,
    shortMessage: 'Hi',
    repiesMessage: 'Thank you for your message, Kindly join our group. Or call on given number'
  },
  {
    id: 2,
    shortMessage: 'No ',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 3,
    shortMessage: 'Not Interested ',
    repiesMessage: 'Thank you for your messages, We understand that you are not interested, But I would request to give once chance and if you are not satisfied then left immediately.'
  },
  {
    id: 4,
    shortMessage: 'Ok',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 5,
    shortMessage: 'Interested ',
    repiesMessage: 'Hi thank you for showing your Interest, Do join our group for more details about our service. for quick updates call on given number.'
  },
  {
    id: 6,
    shortMessage: 'Ok ',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 7,
    shortMessage: 'Hi ',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 8,
    shortMessage: 'Ok',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 9,
    shortMessage: 'Interested ',
    repiesMessage: 'Hi thank you for showing your Interest, Do join our group for more details about our service. for quick updates call on given number.'
  },
  {
    id: 10,
    shortMessage: 'Ok ',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },
  {
    id: 11,
    shortMessage: 'Hi ',
    repiesMessage: 'Thank you for your messages, Kindly Do join our Group or call on Given number for more information about our services.'
  },

]

const Settings = () => {

  const [qickRepliesOpen, setQuickRepliesOpen] = useState(true)
  const [addUpdateReplies, setAddUpdateReplies] = useState(false);
  const [shortcut, setShortcut] = useState('');
  const [replyMessage, setReplyMessage] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);

  const handleEdit = (reply) => {
    console.log("Handle Edit ");
    setSelectedReply(reply);
    setShortcut(reply.shortMessage);
    setReplyMessage(reply.repiesMessage);
    setShowPopup(true);
    setIsEditMode(true);
  };

  const handleClose = () => setShowPopup(false);

  const handleShortcutChange = (event) => {
    setShortcut(event.target.value);
    updateSaveButtonState();
  };

  const handleReplyMessageChange = (event) => {
    setReplyMessage(event.target.value);
    updateSaveButtonState();
  };

  const updateSaveButtonState = () => {
    // Enable the Save button if both input fields have content
    setIsSaveEnabled(shortcut.trim() !== '' && replyMessage.trim() !== '');
  };

  const handleQickReplies = () => {
    setQuickRepliesOpen(true)
    setAddUpdateReplies(false)
  }

  const handleAddQuickReply = () => {
    setAddUpdateReplies(true);
    setQuickRepliesOpen(false)
    setIsEditMode(false);
  }

  const handleArrowClick = () => {
    setReplyMessage("")
    setShortcut("")
    setQuickRepliesOpen(true)
    setAddUpdateReplies(false)
    setIsSaveEnabled(false)
    setShowPopup(false)
  }

  const handleOverlayClick = (event) => {
    // Check if the click is outside the popup
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const handleEditQuickReply = () => {
    setAddUpdateReplies(true)
    setQuickRepliesOpen(false)
    updateSaveButtonState();
  }

  const handleSaveQuickReply = () => {
    console.log("save data");
  }

  return (
    <Container fluid>
      <div style={{ display: 'flex', height: '92vh', border: '1px solid #E5E4E2', marginLeft:'37px' }}>

        {/* <Col xs={1} style={{ border: '1px solid #E5E4E2', height: '92vh', width: '40px' , backgroundColor:'#F6F6F6'}}>
         
        </Col> */}
        {/* Sidebar */}
        <Col xs={3} style={{ padding: 0, borderRight: '1px solid #E5E4E2', width: '20%' }}>
          <div style={{ height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <h6>Settings</h6>
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item action style={{ fontWeight: '550' }}>Profile</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Manage WABAs</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Roles</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Custom Contact Fields</ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Manage Tags</ListGroup.Item>
            <ListGroup.Item action onClick={handleQickReplies} >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <BsSlashLg style={{ marginRight: '15px' }} />
                </div>
                <div style={{ fontWeight: '550' }}>
                  Quick Replies
                </div>
              </div>
              <div style={{ padding: '5px', marginLeft: '1.5rem' }}>
                <span>
                  Manage your quick replies
                </span>
              </div>

            </ListGroup.Item>
            <ListGroup.Item action style={{ fontWeight: '550' }}>Send Template Messages</ListGroup.Item>
          </ListGroup>
        </Col>

        {/* Main Content */}
        {qickRepliesOpen && (<Col style={{ padding: 0, border: '1px solid #E5E4E2', height: '92vh' }}>
          <div style={{ height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <h6>Quick replies</h6>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '16px', height: 'calc(100% - 40px)', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#A5A5A5 transparent' }}>
            <div style={{ flex: '1 0 auto' }}>
              <ListGroup>
                {dummyRepies.map(reply => (
                  <ListGroup.Item key={reply.id}>
                    <span style={{ fontWeight: '500', color: '#A5A5A5' }}>{reply.shortMessage}</span>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div>
                        {reply.repiesMessage}
                      </div>
                      <div style={{ border: 'none', height: '80%', borderRadius: '5px' }}>
                        <Button style={{ border: 'none', background: 'transparent', padding: 0 }}>
                          <RxButton style={{ fontSize: '50px', color: '#A5A5A5' }} onClick={() => handleEdit(reply)} />
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {showPopup && (
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }} onClick={handleOverlayClick}>
                  <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', width: '300px', textAlign: "center", width: '35%', height: '20%' }}>
                    <Button style={{ fontSize: '20px', border: 'none', backgroundColor: 'transparent', width: '100%', color: '#525050', fontWeight: '500' }} onClick={handleEditQuickReply}>Edit</Button>
                    <hr></hr>
                    <Button style={{ fontSize: '20px', border: 'none', backgroundColor: 'transparent', width: '100%', color: '#525050', fontWeight: '500' }}>Delete</Button>
                  </div>
                </div>
              )}

            </div>
            <div style={{ position: 'absolute', bottom: '20px', right: '20px', display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', zIndex: '9999', }}>
              <Button className="btn btn-success" onClick={handleAddQuickReply}>Add Quick Reply</Button>
            </div>
          </div>
        </Col>
        )}

        {addUpdateReplies && (<Col style={{ padding: 0, border: '1px solid #E5E4E2', height: '92vh' }}>
          <div style={{ height: '40px', padding: '8px 16px', borderBottom: '1px solid #E5E4E2', display: 'flex', alignItems: 'center' }}>
            <FaArrowLeft style={{ marginRight: '15px', fontSize: '20px', cursor: 'pointer' }} onClick={handleArrowClick} />
            <h6 style={{ fontWeight: 'bold', margin: 0, flexGrow: 1 }}> {isEditMode ? 'Edit quick reply' : 'Add quick reply'}</h6>
            <Button style={{ marginLeft: 'auto', borderRadius: '5px', backgroundColor: isSaveEnabled ? '#3F906D' : '#E5E4E2', border: 'none', padding: '8px 16px', height: '30px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', color: isSaveEnabled ? '#fff' : '#8a8a8a', }}
              disabled={!isSaveEnabled}
              onClick={handleSaveQuickReply}
            >
              <span>Save</span>
            </Button>
          </div>
          <div style={{ padding: '16px' }}>
            <Form>
              <Form.Group className="mb-3" controlId="shortcutInput">
                <Form.Label style={{ fontSize: '20px', color: '#a6a4a4', fontWeight: '500' }}>Shortcut</Form.Label>
                <p style={{ color: "#c2c2c2" }}>A word that will quickly retrieve this reply</p>
                <Form.Control
                  type="text"
                  // placeholder="A word that will quickly retrieve this reply"
                  className="form-control-lg"
                  value={shortcut}
                  onChange={handleShortcutChange}
                  style={{
                    border: 'none',
                    borderBottom: '2px solid #ccc',
                    borderRadius: 0,
                    boxShadow: 'none'
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="replyMessageTextarea">
                <Form.Label style={{ fontSize: '20px', color: '#a6a4a4', fontWeight: '500' }}>Reply Message</Form.Label>
                <Form.Control as="textarea" rows={3} className="form-control-lg" value={replyMessage} onChange={handleReplyMessageChange} style={{
                  border: 'none', borderBottom: '2px solid #ccc', borderRadius: 0, boxShadow: 'none'
                }} />
              </Form.Group>
              {isEditMode && <Button style={{ padding: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#c27e7e', color: '#fff', border: 'none', borderRadius: '4px', width: '100px', height: '40px' }} onClick={() => { console.log("delete") }}>
                <RiDeleteBin5Line style={{ marginRight: '8px', color: '#fff' }} />
                <span>Delete</span>
              </Button>}
            </Form>
          </div>
        </Col>
        )}

      </div>
    </Container>
  );
};

export default Settings;
